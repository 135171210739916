<template>
    <div>
        <h1 class="title-guide">
            {{ i18n('Local Guide') }}
        </h1>
        <div class="map-container">
            <div v-if="loading" class="loading-indicator"/>
            <div v-if="loading" class="loading-indicator-text"/>
            <div v-else ref="map" class="map"/>
        </div>
        <button class="btn" @click="saveSights">
            {{ i18n('Save selected sights') }}
        </button>
        <div class="select-all-button">
            <button class="btn" @click="selectAll">
                {{ selectAllLabel }}
            </button>
        </div>
        <div class="sights-list">
            <div class="sight" v-for="(sight, index) in sights" :key="index">
                <input type="checkbox" v-model="sight.isSelected">
                <h2>{{ sight.name }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

export default {
    name: 'Map',
    inject: ['http', 'route', 'i18n'],
    data() {
        return {
            map: null,
            placesService: null,
            sights: [],
            maxResults: 25,
            resultsCounter: 0,
            isAllSelected: false,
            selectAllLabel: 'Seleccionar Todos',
            lang: '',
            loading: true,
        };
    },
    mounted() {
        this.loading = true;
        this.loadGoogleMapsScript();
        this.fetch();
        toastr.options = {
            closeButton: true,
            progressBar: true,
            positionClass: 'toast-bottom-right',
            showDuration: '300',
            hideDuration: '1000',
            timeOut: '3000',
            extendedTimeOut: '1000',
            toastClass: 'toastr-custom',
        };
    },

    methods: {
        fetch() {
            this.http.get(this.route('sights.userSights')).then(({ data }) => {
                const tempSights = [];

                // eslint-disable-next-line no-restricted-syntax
                for (const sight of data.sights) {
                    // eslint-disable-next-line no-loop-func
                    this.placesService.textSearch({ query: `${sight.name}, ${sight.city}` }, (results, status) => {
                        // eslint-disable-next-line no-undef
                        if (status === google.maps.places.PlacesServiceStatus.OK) {
                            const place = results[0];

                            // eslint-disable-next-line max-len
                            if (!this.sights.some(existingSight => existingSight.place_id === place.place_id)) {
                                const newSight = {
                                    name: place.name,
                                    city: place.city,
                                    description: place.description || 'No description available',
                                    latitude: place.geometry.location.lat(),
                                    longitude: place.geometry.location.lng(),
                                    rating: place.rating || 0,
                                    address: place.vicinity,
                                    isFetchResult: true,
                                    place_id: place.place_id,
                                    type: place.types[0],
                                };

                                tempSights.push(newSight);

                                // eslint-disable-next-line no-undef
                                const marker = new google.maps.Marker({
                                    map: this.map,
                                    position: place.geometry.location,
                                    icon: {
                                        url: newSight.isFetchResult ? 'https://maps.google.com/mapfiles/ms/icons/purple-dot.png' : undefined,
                                    },
                                });

                                // eslint-disable-next-line no-undef
                                const infowindow = new google.maps.InfoWindow({
                                    content: `
                <div class="infowindow-text">
                  <h2>${place.name}</h2>
                  <p>${place.formatted_address}</p>
                  <p>Valoración: ${place.rating || 'N/A'}</p>
                </div>
                <style>
                  .infowindow-text {
                    font-family: Helvetica;
                    color: black;
                  }
                </style>
              `,
                                });

                                marker.addListener('click', () => {
                                    infowindow.open(this.map, marker);
                                });
                            }
                        }
                    });
                }

                // eslint-disable-next-line no-restricted-syntax
                for (const sight of tempSights) {
                    this.sights.push(sight);
                }
                this.loading = false;
            }).catch(this.errorHandler);
        },
        loadGoogleMapsScript() {
            this.http
                .get(this.route('sights.userSights'))
                .then(({ data }) => {
                    this.lang = data.lang;
                    const script = document.createElement('script');
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}&language=${this.lang}&libraries=places`;
                    script.defer = true;
                    script.async = true;
                    script.onload = () => {
                        this.initMap();
                        this.loading = false; // Set loading to false after the script has loaded
                    };
                    document.body.appendChild(script);
                })
                .catch(this.errorHandler);
        },
        initMap() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        const userLocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        // eslint-disable-next-line no-undef
                        this.map = new google.maps.Map(this.$refs.map, {
                            center: userLocation,
                            zoom: 13,
                        });
                        // eslint-disable-next-line no-undef
                        this.placesService = new google.maps.places.PlacesService(this.map);
                        this.placesService.nearbySearch({
                            location: userLocation,
                            radius: 5000, // 5000 metros
                            type: ['tourist_attraction'],
                        }, this.showPlaces);
                    },
                );
            }
        },
        showPlaces(results, status) {
            // eslint-disable-next-line no-undef
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                for (let i = 0; i < results.length && this.resultsCounter < this.maxResults; i++) {
                    const place = results[i];
                    // Check if the place is already in this.sights based on its place_id
                    // eslint-disable-next-line max-len
                    if (!this.sights.some(existingSight => existingSight.place_id === place.place_id)) {
                        const sight = {
                            name: place.name,
                            city: place.city,
                            description: place.description || 'No description available',
                            latitude: place.geometry.location.lat(),
                            longitude: place.geometry.location.lng(),
                            rating: place.rating || 0,
                            place_id: place.place_id,
                            address: place.vicinity,
                            type: place.types[0],
                            imgUrl: '',
                            isFetchResult: false,
                            isSelected: false,
                        };
                        // eslint-disable-next-line max-len
                        if (place.photos && Array.isArray(place.photos) && place.photos.length > 0) {
                            sight.imgUrl = place.photos[0].getUrl({ maxWidth: 400 });
                        }
                        this.sights.push(sight);
                        // eslint-disable-next-line no-undef
                        const marker = new google.maps.Marker({
                            map: this.map,
                            position: place.geometry.location,
                        });
                        // eslint-disable-next-line no-undef
                        const infowindow = new google.maps.InfoWindow({
                            content: `
            <div class="infowindow-text">
              <h2>${place.name}</h2>
              <p>Dirección: ${place.vicinity}</p>
              <p>Valoración: ${place.rating || 'N/A'}</p>
            </div>
            <style>
              .infowindow-text {
                font-family: Helvetica;
                color: black;
              }
            </style>
          `,
                        });
                        marker.addListener('click', () => {
                            infowindow.open(this.map, marker);
                        });
                    }
                }
            }
        },
        selectAll() {
            this.isAllSelected = !this.isAllSelected;
            this.selectAllLabel = this.isAllSelected ? 'Deseleccionar Todos' : 'Seleccionar Todos';
            this.sights.forEach(sight => {
                sight.isSelected = this.isAllSelected;
            });
        },
        saveSights() {
            const selectedSights = this.sights.filter(sight => sight.isSelected);
            selectedSights.forEach(sight => {
                this.http.post(this.route('sights.store'), {
                    name: sight.name,
                    description: sight.description,
                    type: sight.type,
                    latitude: sight.latitude,
                    longitude: sight.longitude,
                    rating: sight.rating || 0,
                    place_id: sight.place_id,
                    img_url: sight.imgUrl,
                    address: sight.address,
                }).catch(this.errorHandler);
            });
            toastr.success('Sitios guardados exitosamente!');
            this.isAllSelected = false;
            this.selectAllLabel = 'Seleccionar Todos';
            this.sights.forEach(sight => {
                sight.isSelected = false;
            });
        },
    },
};
</script>
<style scoped lang="scss">
@import '../../../sass/enso.scss';
.map-container {
    height: 500px;
    margin-bottom: 20px;
}

.map {
    height: 100%;
}

.title-guide {
    color: $primary;
    font-size: 26px;
    font-weight: bold;
    margin: 0 0 1rem 0;
    text-align: center;
}
.sights-list {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
}

.sight {
    width: 33%;
    margin-bottom: 11px;
    padding: 5px;
    border: 2px solid $greyCcc;
    border-radius: 5px;
}

.sight h2 {
    margin-top: 0;
    margin-bottom: 10px;
}
button {
    padding: 10px 20px;
    background: radial-gradient(circle, rgba(72,95,199,1) 35%, rgba(9,9,121,1) 100%);
    color: $secondary;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background: radial-gradient(circle, rgba(72,95,199,1) 35%, rgba(9,9,121,1) 100%);
}

.select-all-button {
    margin-top: 10px;
}
.loading-indicator {
    display: flex;
    height: 85px;
    width: 85px;
    font-size: 1.5rem;
    border: 4px solid $primary;
    border-top: 4px solid transparent;
    border-radius: 50%;
    animation: spin 1s infinite linear;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loading-indicator-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    font-size: 18px;
    font-weight: bold;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
